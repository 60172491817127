import React from 'react';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import ErrorImg from 'assets/images/errorPages/page-not-found-404.png';
import { mainContainer } from './styles';

const NotFound = () => {
  const { getText } = useTranslations();

  return (
    <div className={`content-container flex-column flex-center ${mainContainer}`}>
      <img src={ErrorImg} alt="404 img" />
      <h2>{getText('pageDoesNotExist')}</h2>
      <p>{getText('pageNotFoundInfo')}</p>
      <Button type="primary" small linkTo="/">
        {getText('goHome')}
      </Button>
    </div>
  );
};

export default NotFound;
