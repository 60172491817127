import { css } from 'emotion';
import { Mobile, Desktop } from 'assets/css/breakpoints';

export const publicMerchantTab = theme =>
  css({
    backgroundColor: theme.white,
  });

export const merchantInfoWrapper = theme =>
  css({
    border: `2px solid ${theme.gray500}`,
    padding: '1em',
    [Mobile]: {
      '& > div': {
        '& > span': {
          marginRight: '1em',
        },
      },
    },
  });

export const icon = theme =>
  css({
    height: '2rem',
    width: '2rem',
    backgroundColor: theme.primary_blue,
    borderRadius: '50%',
    fontSize: '1rem',
    marginTop: '0.375em',
    color: theme.white,
    display: 'table',
    textAlign: 'center',
    '& span': {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
  });

export const merchantInfoText = theme =>
  css({
    marginBottom: 0,
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
  });

export const dealsAddressesWrapper = theme =>
  css({
    border: `2px solid ${theme.gray500}`,
    display: 'flex',
    alignItems: 'center',
    minHeight: '12.5rem',
    '& img': {
      minWidth: '9.375rem',
      [Mobile]: {
        minWidth: '6.25rem',
      },
    },
  });

export const dealsAddressesDetails = theme =>
  css({
    paddingLeft: '1.25em',
    '& p': {
      color: theme.gray100,
      fontSize: '1.125rem',
      marginBottom: '0.25em',
    },
    '& label': {
      display: 'block',
      fontSize: '3rem',
      fontWeight: 500,
    },
    '& a': {
      fontSize: '1.125rem',
      color: theme.blue2,
    },
  });

export const ratingsContainer = theme =>
  css({
    border: `2px solid ${theme.gray500}`,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1em',
    paddingBottom: '1em',

    [Mobile]: {
      justifyContent: 'space-around',
    },
  });

export const ratingWrapper = theme =>
  css({
    textAlign: 'center',
    '& .rating': {
      display: 'block',
      fontSize: '2.375rem',
      fontWeight: 500,
      color: theme.gray100,
    },
    '& .description': {
      fontSize: '0.75rem',
      color: theme.gray100,
      [Desktop]: {
        marginRight: '1em',
      },
      [Mobile]: {
        display: 'block',
      },
    },
    '& .ant-rate': {
      marginTop: '0.5em',
    },
    '& .ant-rate-star:not(:last-child)': {
      marginRight: '0.0625em',
    },
  });

export const dealsTitle = theme =>
  css({
    fontSize: '1.125rem',
    fontWeight: 700,
    color: theme.gray100,
    marginBottom: '0.75em',
  });

export const aboutTitle = theme =>
  css({
    fontSize: '1.4375rem',
    fontWeight: 700,
    color: theme.gray100,
    marginTop: '1.25em',
  });

export const descriptionParagraph = theme =>
  css({
    fontSize: '1.125rem',
    color: theme.gray100,
  });

export const addressesTitle = theme =>
  css({
    fontSize: '1.125rem',
    fontWeight: 700,
    color: theme.gray100,
    marginTop: '1.875em',
    marginBottom: '1em',
  });

export const ratingsTitleWrapper = css({
  marginTop: '1.5em',
  marginBottom: '1.125em',
  '& h5': {
    fontWeight: 700,
    fontSize: '1.4375rem',
  },
});

export const noAddressesContainer = css({
  marginTop: '2.5em',
  marginBottom: '5em',
  alignItems: 'center',
  [Mobile]: {
    marginTop: '5em',
  },
});

export const noAddressesText = theme =>
  css({
    color: theme.gray200,
    fontSize: '1.125rem',
    fontWeight: 700,
    [Mobile]: {
      marginTop: '1.25em',
    },
    [Desktop]: {
      marginLeft: '1.5em',
    },
  });

export const addressesList = css({
  marginTop: '1.875em',
  marginBottom: '5em',
  [Mobile]: {
    marginBottom: '2.5em',
  },
});

export const addressBox = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1em',
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      minHeight: '38.4375rem',
    },
    [Mobile]: {
      border: `2px solid ${theme.gray500}`,
    },
  });

export const topContentWrapper = theme =>
  css({
    width: '100%',
    borderBottom: `1px solid ${theme.gray500}`,
    paddingBottom: '0.5em',
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray300,
    },
  });

export const addressTitle = theme =>
  css({
    display: 'flex',
    alignItems: 'flex-start',
    '& h6': {
      fontSize: '1.1875rem',
      fontWeight: 700,
      color: theme.gray100,
      marginBottom: 0,
    },
    '& button': {
      border: 'none',
      background: theme.white,
      marginLeft: 'auto',
      fontSize: '1.1875rem',
    },
    '& button:hover': {
      background: theme.ui_hover,
    },
  });

export const messageWrapper = theme =>
  css({
    background: theme.ui_hover_rgba,
    padding: '0.5625em',
    textAlign: 'center',
    marginTop: '0.3125em',
    width: '100%',
    '& p': {
      display: 'flex',
      fontSize: '0.75rem',
      marginBottom: '0.25em',
      textAlign: 'left',
    },
    '& span': {
      fontSize: '0.875rem',
      marginRight: '0.4375em',
    },
    '& button': {
      fontSize: '0.75rem',
      textDecoration: 'underline',
      color: theme.blue2,
      background: 'transparent',
      border: 'none',
    },
    '& button:hover': {
      textDecoration: 'underline',
      color: theme.primary_blue_hover,
    },
  });

export const infoTextWrapper = theme =>
  css({
    marginTop: '0.625em',
    marginBottom: 0,
    color: theme.gray100,
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
  });

export const titleForm = theme =>
  css({
    marginBottom: '0.875em',
    fontWeight: 500,
    fontSize: '2.375rem',
    color: theme.background100,
    [Mobile]: {
      fontSize: '1.375rem',
    },
  });

export const subtitle = theme =>
  css({
    fontSize: '1rem',
    color: theme.gray100,
  });

export const addressFormWrapper = () =>
  css({
    marginTop: '1.875em',
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '0.8125rem !important',
      fontWeight: 500,
    },
    '& .ant-input::placeholder, .ant-select-selection-placeholder': {
      fontSize: '0.75rem',
    },
    '& .ant-form-item-label': {
      paddingBottom: '0 !important',
    },
  });

export const formSectionWrapper = theme =>
  css({
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.375em 1.75em',
      marginBottom: '1.4375em',
    },
  });

export const merchantProfileTags = theme =>
  css({
    width: '70%',
    marginBottom: '0px !important',
    [Mobile]: {
      width: '100%',
      marginBottom: '2em !important',
    },
    '.ant-tag.ant-tag-checkable': {
      background: theme.white,
      color: theme.gray100,
      border: `1px solid ${theme.gray400}`,
      borderRadius: 4,
      padding: '0.25em 0.5em',
      marginBottom: '0.5em',
    },
    '.ant-tag.ant-tag-checkable.ant-tag-checkable-checked': {
      background: theme.skyblue,
      border: `1px solid ${theme.primary_blue}`,
    },
  });

export const nameInput = css({
  width: '70%',
  [Mobile]: {
    width: '100%',
  },
});

export const mobileLabel = css({
  fontWeight: 700,
});

export const countryStateWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '70%',
  [Mobile]: {
    flexDirection: 'column',
    width: '100%',
  },
});

export const countryInput = css({
  width: '47%',
  '& .ant-select': {
    width: '100%',
  },
  [Mobile]: {
    width: '100%',
    marginBottom: '1.375em',
  },
});

export const stateInput = css({
  width: '47%',
  [Mobile]: {
    width: '100%',
  },
});

export const cityPostcodeWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '70%',
  [Mobile]: {
    width: '100%',
  },
});

export const cityInput = css({
  width: '70%',
  [Mobile]: {
    flexDirection: 'row !important',
  },
});

export const postCodeInput = css({
  width: '24%',
  [Mobile]: {
    flexDirection: 'row !important',
  },
});

export const addressLineInput = css({
  marginBottom: '1.375em',
  width: '70%',
  [Mobile]: {
    flexDirection: 'column',
    width: '100%',
  },
});

export const phoneInput = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '70%',
  marginBottom: '1.375em',
  '& .ant-row.ant-form-item:first-child': {
    width: '20%',
    display: 'inline-block',
    marginBottom: 0,
  },
  '& .ant-row.ant-form-item:nth-child(2)': {
    width: '75%',
    display: 'inline-block',
    float: 'right',
    marginBottom: 0,
  },
  '& .phone-number': {
    width: '100%',
    '& .ant-input-number-handler-wrap': { display: 'none' },
  },
  [Mobile]: {
    width: '100%',
    marginBottom: '0.9375em',
  },
});

export const contactPersonInput = css({
  width: '70%',
  marginBottom: '0.625em !important',
  [Mobile]: {
    width: '100%',
  },
});

export const dayTimeWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '70%',
  [Mobile]: {
    width: '100%',
  },
});

export const dayTimeInput = css({
  width: '47%',
  '& .ant-picker': {
    width: '100%',
  },
  [Mobile]: {
    flexDirection: 'row !important',
  },
});

export const formButtons = isInModal =>
  css({
    marginBottom: isInModal ? 0 : '4.875em',
    display: 'flex !important',
    justifyContent: 'space-between',
    '& .confirm-btn': {
      width: isInModal && '100%',
    },
    '& span': {
      margin: 'auto',
    },
    [Mobile]: {
      flexDirection: 'column-reverse',
      marginBottom: '3em',
      '& .confirm-btn': {
        minHeight: '2.5rem',
      },
    },
  });

export const goBackButton = css({
  fontWeight: '500 !important',
  [Desktop]: {
    paddingLeft: '0 !important',
  },
  [Mobile]: {
    marginBottom: '0.625em',
  },
});

export const languagesFormWrapper = css({
  marginBottom: '0px !important',
});

export const tabs = theme =>
  css({
    '& .ant-tabs-tab': {
      background: 'none !important',
      border: 'none !important',
    },
    '& .ant-tabs-tab:first-child': {
      paddingLeft: '0px !important',
    },
    '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
      borderBottom: `2px solid ${theme.primary_yellow} !important`,
    },
    '& .ant-tabs-nav::before': {
      borderBottom: 'none !important',
    },
  });

export const linkButtonStyles = css({
  padding: '0 1em 0 0',
  whiteSpace: 'break-spaces',
  fontSize: '1.125rem',
});
