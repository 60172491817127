import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { BorderlessShadowlessGrayBox, Tabs, TabPane } from 'components/ui';
import { Button } from 'components/UIExternal';
import useError from 'services/errorHandling/useError';
import { getBusinessOverview } from 'services/api/businessesService';
import tickIcon from 'assets/images/icn-tick-filled.svg';
import { mb, mr } from 'assets/css/globalCSS';
import { OverviewTab, ProfileTab, DealsTab, RatingsTab } from './components';
import { PublicMerchantPage, title, titleWrapper, countryLabel } from './styles';

const MerchantPage = () => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const history = useHistory();
  const tabKey = history.location.pathname.split('/')[3];
  const { setError } = useError();
  const { businessId } = useParams();
  const [activeTab, setActiveTab] = useState(tabKey);
  const [businessOverview, setBusinessOverview] = useState();

  useEffect(() => {
    fetchBusinessOverview();
  }, []);

  useEffect(() => {
    tabKey !== activeTab && setActiveTab(tabKey);
  }, [tabKey]);

  const fetchBusinessOverview = async () => {
    const [res, err] = await getBusinessOverview(businessId);
    err ? setError(err) : setBusinessOverview(res);
  };

  const onTabClickHandler = e => {
    setActiveTab(e);
    history.replace(`/merchant/${businessId}/${e}`);
  };

  return businessOverview ? (
    <PublicMerchantPage theme={theme}>
      <BorderlessShadowlessGrayBox theme={theme}>
        <div className={`content-container  ${titleWrapper}`}>
          <div>
            <div className="flex">
              <h1 className={title(theme)}>{getDynamicTranslation(businessOverview?.name)?.name}</h1>
              <img src={tickIcon} alt="Green tick" />
            </div>
            <span className="icon-Location" />
            <label className={countryLabel(theme)}>{businessOverview?.country}</label>
          </div>
          <Button type="link" small linkTo={`/chat?userId=${businessOverview?.merchantId}`}>
            <FeatherIcon icon="message-square" size={12} className={mr({ lg: 5 })} />
            {getText('messageMerchant')}
          </Button>
        </div>
      </BorderlessShadowlessGrayBox>
      <div className="content-container">
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          onTabClick={onTabClickHandler}
          theme={theme}
        >
          <TabPane tab={getText('overview')} key="overview">
            <OverviewTab />
          </TabPane>
          <TabPane tab={getText('merchantProfileAddresses')} key="merchant-profile">
            <ProfileTab />
          </TabPane>
          <TabPane tab={getText('deals')} key="deals">
            <DealsTab />
          </TabPane>
          <TabPane tab={getText('ratingsFeedback')} key="ratings">
            <RatingsTab />
          </TabPane>
        </Tabs>
      </div>
    </PublicMerchantPage>
  ) : (
    <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
      <Spin size="large" />
    </div>
  );
};

export default MerchantPage;
