import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isFunction } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { isTokenValid } from '@oneecosystem/authenticate';
import { Rate, Row, Col, Popover } from 'antd';
import { useTheme } from 'emotion-theming';
import { isMobile } from 'react-device-detect';
import FeatherIcon from 'feather-icons-react';
import DiscountStamp from 'components/deal/DiscountStamp';
import { Button } from 'components/UIExternal';
import { ThumbnailContainer } from 'components/deal/Deal/styles';
import { DealPrice } from 'components/shared';
import { dealDiscountStamp } from 'screens/Private/MerchantOffice/components/dealsStyles';
import { deliveryMethodSelect } from 'enums';
import { formatDefaultDate } from 'utils';
import { removeConnectedDeal } from 'services/api/dealsService';
import { addDealToWishlist, deleteDealFromWishlist } from 'services/api/wishlist';
import useError from 'services/errorHandling/useError';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import { blackTextLink, ml } from 'assets/css/globalCSS';
import {
  DealDescription,
  dealTitle,
  dealContainer,
  thumbnail,
  favoriteIcon,
  dealRatingWrapper,
  starRatings,
  ratingsCountLabel,
  ratingsCountLink,
  dealEndDateWrapper,
  couponIcon,
  link,
  verticalStyleWrapper,
  couponInfoWrapper,
  popoverWrapper,
  businessNameContainer,
  businessNameLink,
} from './styles';

const ListingDeal = ({
  deal,
  isVertical,
  isWishlistDeal,
  isMerchant,
  mainDealId,
  mainDealName,
  reloadPage,
  declinedBusiness,
}) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const { hash } = useLocation();
  const isLoggedIn = isTokenValid();
  const {
    id,
    name,
    media,
    isInWishList,
    businessName,
    businessId,
    rating,
    title,
    reviewsCount,
    availableCoupons,
    availableCouponsCount,
    soldCoupons,
    couponsCount,
    couponsQuantity,
    description,
    deliveryMethodId,
    dealEndDate,
    endDate,
    discount,
    dealDetails,
  } = deal;

  const [addedToWishlist, setAddedToWishlist] = useState(isInWishList);
  const [wishlistBtnDisabled, setWishlistBtnDisabled] = useState(!isLoggedIn);

  useEffect(() => {
    hash === 'wishlist' && setAddedToWishlist(true);
  }, []);

  const handleDealWishList = async wishListRequest => {
    const [, err] = await wishListRequest(id);
    err && setError(err);
    isWishlistDeal ? reloadPage(id) : setAddedToWishlist(prev => !prev);

    setWishlistBtnDisabled(false);
  };

  const manageWishlist = e => {
    e.preventDefault();
    setWishlistBtnDisabled(true);
    addedToWishlist ? handleDealWishList(deleteDealFromWishlist) : handleDealWishList(addDealToWishlist);
    isFunction(reloadPage) && reloadPage(id);
  };

  const handleDeleteConnectedDeal = async () => {
    const payload = {
      mainDealId,
      connectedDealId: id,
    };

    const [, err] = await removeConnectedDeal(payload);
    if (err) return setError(err);
    isFunction(reloadPage) && reloadPage(id);
  };

  const businessIconConfig = {
    size: 15,
    stroke: declinedBusiness ? theme.red : theme.green,
    icon: declinedBusiness ? 'minus-circle' : 'check-circle',
    className: ml({ lg: 5 }),
  };

  return (
    <Button
      disabled={declinedBusiness}
      type="link"
      linkTo={`/deal/${mainDealName}`}
      key={id}
      className={link(isWishlistDeal, theme)}
    >
      <Row style={{ marginBottom: 32 }} gutter={[24, 24]}>
        <Col className="gutter-row" lg={isVertical || isWishlistDeal ? 24 : 8} span={24}>
          <ThumbnailContainer>
            <img src={media?.url ?? media?.at(0)?.url ?? placeholderThumbnail} alt="Deal" className={thumbnail} />
            {discount > 0 && <DiscountStamp deal={deal} className={dealDiscountStamp} />}
            <Button
              type="link"
              small
              disabled={wishlistBtnDisabled}
              onClick={e => manageWishlist(e)}
              className={favoriteIcon(theme)}
            >
              {!addedToWishlist ? (
                <FeatherIcon icon="heart" size={17} stroke={theme.gray300} />
              ) : (
                <FeatherIcon icon="heart" size={17} stroke={theme.white} fill={theme.red} />
              )}
            </Button>
          </ThumbnailContainer>
        </Col>
        <Col
          className={`gutter-row  ${(isVertical || isWishlistDeal) && verticalStyleWrapper}`}
          lg={isVertical || isWishlistDeal ? 24 : 16}
          span={24}
        >
          <div style={{ height: '100%' }} className="flex-column space-between deal-components">
            <div className={dealContainer(theme)} style={{ minHeight: isWishlistDeal && !isMobile && 72 }}>
              <Link to={`/deal/${mainDealName}`} className={dealTitle}>
                {/* TODO: StartChat component need to be refactored and leave dealDetails only */}
                {getDynamicTranslation(dealDetails ?? title)?.title}
              </Link>
              {isMerchant && (
                <Popover
                  content={
                    <Button type="link" small onClick={handleDeleteConnectedDeal}>
                      {getText('removeFromConnected')}
                    </Button>
                  }
                  overlayClassName={popoverWrapper(theme)}
                  zIndex={900}
                  placement="bottomRight"
                  trigger="click"
                >
                  <FeatherIcon icon="more-vertical" size={20} />
                </Popover>
              )}
            </div>
            <DealPrice deal={deal} dataId="listing-deal-price-panel" />
            <div
              className={`${
                isMobile || isVertical || isWishlistDeal ? 'flex-column' : 'flex'
              } list-deal-user-rating-coupons`}
            >
              <div
                className="flex list-deal-user-rating"
                style={{ flexWrap: (isMobile || isVertical) && 'wrap', alignItems: 'center' }}
              >
                <div className={businessNameContainer(theme)}>
                  <Button
                    small
                    disabled={declinedBusiness}
                    type="link"
                    linkTo={`/merchant/${businessId}/overview`}
                    className={businessNameLink}
                  >
                    {/* TODO: StartChat component need to be refactored and leave dealDetails only */}
                    {getDynamicTranslation(businessName)?.name}
                  </Button>
                </div>
                <FeatherIcon {...businessIconConfig} />
                <div className={`deal-rating-wrapper ${dealRatingWrapper(theme, isVertical, isMobile)} mr12`}>
                  <Rate className={starRatings(theme)} value={rating} disabled />
                  {reviewsCount > 0 ? (
                    <Link className={ratingsCountLink(theme)} to={`/deal/${name}/reviews`}>
                      {reviewsCount}
                    </Link>
                  ) : (
                    <label className={ratingsCountLabel(theme)}>{reviewsCount}</label>
                  )}
                </div>
              </div>
              <div className={couponInfoWrapper(isMobile, isVertical, isWishlistDeal, theme)}>
                <span className={`icon-Coupon-2 ${couponIcon(isMobile, isVertical, isWishlistDeal)}`} />
                <label>{`${getText('coupons')}: `}</label>
                {isVertical && (
                  <span className={`bold-text ${blackTextLink(theme)}`}>
                    {getText('ofLeft', {
                      count: availableCoupons || availableCouponsCount || 0,
                      total: couponsCount + soldCoupons || couponsCount || availableCoupons + soldCoupons,
                    })}
                  </span>
                )}
                {isWishlistDeal && (
                  <span className="bold-text">
                    {getText('ofLeft', { count: availableCoupons, total: couponsQuantity })}
                  </span>
                )}
                {!isVertical && !isWishlistDeal && (
                  <Button type="link" small className="bold-text">
                    {getText('ofLeft', { count: availableCouponsCount, total: couponsCount })}
                  </Button>
                )}
              </div>
            </div>
            <DealDescription title={description} style={{ minHeight: isWishlistDeal && !isMobile && 49 }}>
              {/* TODO: StartChat component need to be refactored and leave dealDetails only */}
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: getDynamicTranslation(dealDetails ?? description)?.description,
                }}
              />
            </DealDescription>
            <div className={dealEndDateWrapper(isVertical, isWishlistDeal)}>
              <div className="deal-ends-on">
                <span className="icon-Ends-on" />
                <label>{getText('endsOn')} </label>
                <label className="bold-text">{formatDefaultDate(isWishlistDeal ? endDate : dealEndDate)}</label>
              </div>
              <div className="deal-delivery-method">
                <span className={`delivery-icon ${deliveryMethodSelect[deliveryMethodId]?.icon}`} />
                <label>{getText(deliveryMethodSelect[deliveryMethodId]?.label)}</label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Button>
  );
};

ListingDeal.propTypes = {
  deal: PropTypes.object,
  isVertical: PropTypes.bool,
  isWishlistDeal: PropTypes.bool,
  isMerchant: PropTypes.bool,
  mainDealId: PropTypes.number,
  mainDealName: PropTypes.string,
  reloadAllLoadedPages: PropTypes.func,
  reloadPage: PropTypes.func,
  declinedBusiness: PropTypes.bool,
};

export default ListingDeal;
