import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isFunction, isString } from '@veraio/core';
import BodyOverflow from '../BodyOverflow';
import { Icon } from '../../ui/Icon';
import {
  modalContainer,
  modalScrollContainer,
  modalContentContainer,
  headerContainer,
  closeIconStyles,
} from './styles';

export const Modal = forwardRef((props, ref) => {
  const { children, show = false, withoutClose, closeIcon, onClose, onOpen, className, modalTitle } = props;
  const [showModal, setShowModal] = useState(show);
  const theme = useTheme();
  const classList = isString(className) && className;

  useImperativeHandle(ref, () => ({
    open: () => open(),
    close: () => close(),
  }));

  useEffect(() => {
    if (withoutClose) return;

    const handleKeyDown = e => (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) && close();

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    show ? document.body.style.setProperty('overflow', 'hidden') : document.body.style.removeProperty('overflow');
    setShowModal(show);
  }, [show]);

  const close = () => {
    isFunction(onClose) && onClose();
    setShowModal(false);
  };

  const open = () => {
    isFunction(onOpen) && onOpen();
    setShowModal(true);
  };

  return (
    showModal && (
      <BodyOverflow fixed fullScreen className={modalContainer} zIndex={5000} onClick={close}>
        <section className={`${modalScrollContainer(props)} ${classList}`}>
          <div role="presentation" className={modalContentContainer(theme)} onClick={e => e.stopPropagation()}>
            {modalTitle ? (
              <div className={headerContainer}>
                <h3>{modalTitle}</h3>
                {closeIcon ?? <Icon iconName="las la-times" size={20} onClick={close} className={closeIconStyles} />}
              </div>
            ) : (
              closeIcon ?? <Icon iconName="las la-times" size={20} onClick={close} className={closeIconStyles} />
            )}
            {children}
          </div>
        </section>
      </BodyOverflow>
    )
  );
});

Modal.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool,
  withoutClose: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  className: PropTypes.string,
  closeIcon: PropTypes.any,
  modalTitle: PropTypes.any,
};
