/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isArray } from '@veraio/core';
import { Form, Tabs } from 'antd';
import { Dropdown } from 'components/UIExternal';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';
import { translationsTabs, translationsCountryDropdown, translationsInputSkeleton } from './styles';

const InputsWithTranslations = forwardRef(
  ({ value: initialValue, onChange: formOnChange, id: formId, formInstance, inputs }, ref) => {
    const { getText, allEnvLanguages, language } = useTranslations();
    const theme = useTheme();
    const [languageToRemove, setLanguageToRemove] = useState(null);
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [allTabs, setAllTabs] = useState(null);
    const [activeTab, setActiveTab] = useState(null);
    const languagesDropdownRef = useRef();
    const inputNames = inputs.map(el => el.formItemProps.name);
    const formValue = isArray(initialValue) && initialValue.length ? initialValue : [{ languageCode: language?.code }];

    useImperativeHandle(ref, () => ({
      validate: () => {
        const invalidField = formValue.find(el => inputNames.some(key => !el[key]));
        if (!invalidField) return;

        handleTabChange(invalidField?.languageCode);
        setTimeout(formInstance?.validateFields);
        formInstance?.scrollToField(inputNames?.at(0), {
          behavior: actions =>
            actions.forEach(({ el, top, left }) => el.scroll({ top: top - 280, left, behavior: 'smooth' })),
        });
        return invalidField;
      },
    }));

    useEffect(() => {
      language?.code && handleTabChange(language.code);
    }, [language?.code]);

    useEffect(() => {
      language?.code && allEnvLanguages && predefinedLanguages();
    }, [allEnvLanguages?.length, formValue?.length, language?.code]);

    const predefinedLanguages = () => {
      // Set all selected tabs to which have translations
      setAllTabs(formValue.map(el => allEnvLanguages.find(lang => lang.code === el.languageCode)));
      // Set all languages for options into dropdown
      setAvailableLanguages(allEnvLanguages?.filter(lang => !formValue.some(el => el.languageCode === lang.code)));
    };

    const handleAddLanguage = languageCode => {
      formOnChange({ target: { id: formId, value: formValue?.concat({ languageCode }) } });
      setActiveTab(languageCode);
      languagesDropdownRef.current?.reset();
    };

    const handleRemoveLanguage = () => {
      const newLanguages = formValue.filter(el => el?.languageCode !== languageToRemove);
      formOnChange({ target: { id: formId, value: newLanguages } });
      languageToRemove === activeTab && handleTabChange(newLanguages?.at(0)?.languageCode);
      setLanguageToRemove(null);
    };

    const handleTabChange = langCode => {
      const correspondingInput = formValue?.find(detail => detail.languageCode === langCode) ?? formValue.at(0);
      setActiveTab(correspondingInput?.languageCode);
      const formValues = inputNames.reduce((acc, key) => ({ ...acc, [key]: correspondingInput?.[key] ?? null }), {});
      formInstance.setFieldsValue(formValues);
    };

    const handleInputChange = ({ target: { value: inputValue, id: inputId } }) => {
      const value = formValue.map(el => (el?.languageCode === activeTab ? { ...el, [inputId]: inputValue } : el));
      formOnChange({ target: { id: formId, value } });
    };

    return (
      <>
        <Tabs
          hideAdd
          className={translationsTabs(theme, !allTabs?.length)}
          tabBarExtraContent={
            <Dropdown
              noClear
              withSearch
              ref={languagesDropdownRef}
              className={translationsCountryDropdown}
              options={availableLanguages}
              placeholder={getText('selectLanguage')}
              displayKey="name"
              uniqueKey="code"
              onChange={handleAddLanguage}
              mapValue={el => el?.code}
            />
          }
          onEdit={setLanguageToRemove}
          activeKey={activeTab}
          onChange={handleTabChange}
          type="editable-card"
        >
          {allTabs?.length
            ? allTabs?.map(lang => (
              <Tabs.TabPane closable={allTabs.length > 1} key={lang?.code} tab={lang?.name}>
                {inputs?.map(input => {
                  const { inputComponent: Component, formItemProps, inputProps } = input;
                  const { name } = formItemProps;

                  return (
                    <Form.Item
                      key={name}
                      labelAlign="left"
                      rules={[{ required: true, message: getText('fieldIsRequired') }]}
                      getValueProps={() => ({
                        value: formValue?.find(detail => detail.languageCode === activeTab)?.[name],
                      })}
                      data-id={`form-item-${name}`}
                      {...formItemProps}
                    >
                      <Component
                        onChange={handleInputChange}
                        onBlur={() => formInstance?.validateFields([name])}
                        data-id={`input-${name}`}
                        {...inputProps}
                      />
                    </Form.Item>
                  );
                })}
              </Tabs.TabPane>
            )) : (
              <div role="presentation" className={translationsInputSkeleton}>
                {inputs?.map(input => {
                  const { inputComponent: Component, formItemProps, inputProps } = input;
                  const { name } = formItemProps;

                  return (
                    <Form.Item
                      key={name}
                      labelAlign="left"
                      rules={[{ required: true, message: getText('fieldIsRequired') }]}
                      getValueProps={() => ({
                        value: formValue?.find(detail => detail.languageCode === activeTab)?.[name],
                      })}
                      data-id={`form-item-${name}`}
                      {...formItemProps}
                    >
                      <Component
                        onChange={handleInputChange}
                        onBlur={() => formInstance?.validateFields([name])}
                        data-id={`input-${name}`}
                        {...inputProps}
                        disabled
                      />
                    </Form.Item>
                  );
                })}
              </div>
            )
          }
        </Tabs>
        <ConfirmationPopup
          isModalVisible={!!languageToRemove}
          handleOk={handleRemoveLanguage}
          handleCancel={() => setLanguageToRemove(null)}
          text={getText('sureCloseLanguageTab')}
        />
      </>
    );
  },
);

InputsWithTranslations.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  id: PropTypes.string,
  formInstance: PropTypes.object,
  inputs: PropTypes.array,
};

export default InputsWithTranslations;
