import queryString from 'query-string';
import { isNumber, pick } from '@veraio/core';

export const getDealReviewsParams = options => {
  const { reviewsSorting, stars } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(reviewsSorting) && { reviewsSorting }),
      ...(isNumber(stars) && { stars }),
    },
    { arrayFormat: 'index' },
  );
};
