import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { startCase } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { calculateDealPrice } from '@oneecosystem/dealshaker-core';
import { Rate, Row, Col, Modal, Spin, Popover } from 'antd';
import { FacebookShareButton } from 'react-share';
import VideoThumbnail from 'react-video-thumbnail';
import FeatherIcon from 'feather-icons-react';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
import { Button, useDeepEffect, Breadcrumbs } from 'components/UIExternal';
import { DealDetailsSeeOnMapAddresses } from 'components/screens';
import PromotionsModal from 'components/PromotionsModal';
import CloseConfirmationPopup from 'components/popups/CloseConfirmationPopup';
import { useUser, useShoppingCart } from 'stores';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import { dealStatus, dealStatusInverse, errorCodes, refusedReviewsStatusInverse } from 'enums';
import { formatDefaultDate } from 'utils';
import { getDealReviewsSummary, getReviewsList } from 'services';
import { getDealDetails, updateDealStepData, changeDealStatus } from 'services/api/dealsService';
import useError from 'services/errorHandling/useError';
import { mb, mr, blackTextLink } from 'assets/css/globalCSS';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import TickIcon from 'assets/images/icn-tick-filled.svg';
import {
  ConnectedDealsPanel,
  DealContentPanel,
  ReviewsAndCommentsPanel,
  ImageGalleryPanel,
  PricePanel,
  StatusControlButtons,
} from './components';
import { dealDetailsBreadcrumbs } from './config';
import { NotFound } from '../errorPages';
import {
  starRatings,
  ratingsCountLabel,
  dealDetailsWrapper,
  name,
  textUnderline,
  textFormat,
  dealRatingWrapper,
  dealEndDateWrapper,
  dealDetailsContainer,
  messagesLink,
  socialContainer,
  contentRow,
  userContainer,
  imageInfoOverlay,
  video,
  galleryModal,
  youTubePlayIcon,
  previewOptions,
  merchantOptions,
  statusLabel,
  merchantButtons,
  popoverWrapper,
  imgModalTitle,
  merchantNameLbl,
  facebookButton,
  statusTag,
  availableBvPoints,
} from './styles';

const DealDetailsPage = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { dealName } = useParams();
  const { addDeal } = useShoppingCart();
  const history = useHistory();
  const location = useLocation();
  const { setError } = useError();
  const isInPreview = history.location.pathname.includes('/deal/preview/');
  const { userInfo, isAuthenticated } = useUser();
  const [deal, setDeal] = useState(null);
  const [dealImages, setDealImages] = useState([]);
  const [reviewsSummary, setReviewsSummary] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mediaIndexForPreview, setMediaIndexForPreview] = useState(0);
  const [isPromotionsModalVisible, setIsPromotionsModalVisible] = useState(false);
  const [isMerchant, setIsMerchant] = useState(false);
  const [closeDealModalVisible, setCloseDealModalVisible] = useState(false);
  const isUsersDeal = userInfo?.id === deal?.merchantId || false;
  const [connectedDeals, setConnectedDeals] = useState(null);
  const [reviewsList, setReviewsList] = useState(null);
  const [merchantAddressesList, setMerchantAddressesList] = useState(null);

  useDeepEffect(() => {
    fetchData();
  }, [dealName]);

  useEffect(() => {
    if (isAuthenticated) {
      const redirectToShoppingCart = localStorage.getItem('redirectToShoppingCart');
      if (redirectToShoppingCart) {
        history.push('/shopping-cart');
        localStorage.removeItem('redirectToShoppingCart');
      }
    }
  }, []);

  const handleCloseDealOk = async () => {
    setCloseDealModalVisible(false);
    const [, err] = await changeDealStatus(deal?.id, dealStatus.closed);
    err ? setError(err) : fetchDealDetail();
  };

  const fetchData = async () => {
    // fetch deal details
    const [detailRes, detailErr] = await fetchDealDetail();
    if (detailRes) fetchReviewsList(detailRes?.id);

    setMerchantAddressesList(
      detailRes.addresses?.map(el => ({
        latitude: el.latitude,
        longitude: el.longitude,
        title: detailRes.businessName,
        address: `${el?.street}, ${el?.city}, ${el?.country}`,
      })),
    );

    detailErr?.data?.errors?.[0]?.code !== errorCodes.DEAL_NOT_FOUND &&
      !refusedReviewsStatusInverse[detailRes?.statusId] &&
      fetchReviewSummary(detailRes?.id);
  };

  const fetchDealDetail = async () => {
    const [detailRes, detailErr] = await getDealDetails(dealName);
    detailErr?.data?.errors?.[0]?.code ? setDeal(detailErr.data?.errors?.[0]?.code) : setDealFromRes(detailRes);
    return [detailRes, detailErr];
  };

  const fetchReviewsList = async dealId => {
    const [res, err] = await getReviewsList(dealId, { pageSize: 4 });
    err ? setError(err) : setReviewsList(res);
  };

  const fetchReviewSummary = async id => {
    const [res, err] = await getDealReviewsSummary(id);
    err ? setError(err) : setReviewsSummary(res);
  };

  const showHidePromotionsModal = () => setIsPromotionsModalVisible(!isPromotionsModalVisible);

  const renderVideo = url => <ReactPlayer controls className={video} url={url} />;

  const setDealFromRes = res => {
    setDeal(res);
    setConnectedDeals(res?.connectedDeals?.map(deal => calculateDealPrice(deal)));
    setIsMerchant(res?.isOwner);
    const media = res?.media?.map((item, i) => {
      if (item.typeId === MediaTypesEnum.Link.id) {
        return {
          renderItem: () => renderVideo(`www.youtube.com/watch?v=${item.url}`),
          original: item.thumbnailUrl,
          thumbnail: item.thumbnailUrl,
        };
      }
      if (item.typeId === MediaTypesEnum.Video.id) {
        return {
          renderItem: () => renderVideo(item.url),
          renderThumbInner: () => (
            <div className={youTubePlayIcon(theme)}>
              <span className="icon-Play" />
              <VideoThumbnail height={60} width={90} videoUrl={item.url} />
            </div>
          ),
          original: item.url,
          thumbnail: item.thumbnailUrl,
        };
      }

      return {
        renderItem: () => (
          <Button
            key={i}
            type="link"
            style={{ background: 'none', border: 'none' }}
            onClick={() => {
              setMediaIndexForPreview(i);
              setIsModalVisible(true);
            }}
          >
            <img className="image-gallery-image" alt="deal" src={item.url} />
          </Button>
        ),
        thumbnail: item.thumbnailUrl,
        original: item.url,
      };
    });
    if (!res?.media?.length) media?.push({ original: placeholderThumbnail });
    setDealImages(media);
  };

  const countType = typeId => {
    const countTypes = deal.media.filter(item => item.typeId === typeId);
    return countTypes.length;
  };

  if (deal === null) {
    return (
      <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
        <Spin size="large" />
      </div>
    );
  }

  if (deal === errorCodes.DEAL_NOT_FOUND) return <NotFound />;

  const businessName = getDynamicTranslation(deal.businessName);
  const dealDetails = getDynamicTranslation(deal.dealDetails);

  return (
    <>
      <section className={`content-container ${dealDetailsContainer}`}>
        {isInPreview &&
          (!isUsersDeal ? (
            <Row className={previewOptions(theme)}>
              <Col span={24} lg={3}>
                <Button type="link" small className={blackTextLink(theme)} linkTo={`/edit-deal/${deal.id}`}>
                  {getText('returnToEdit')}
                </Button>
              </Col>
              <Col span={24} lg={{ offset: 14, span: 4 }}>
                <label>{getText('readyToPublishDeal')}</label>
              </Col>
              <Col span={24} lg={3}>
                <Button
                  type="primary"
                  small
                  onClick={() =>
                    deal.id &&
                    updateDealStepData({}, 5, deal.id, setError).then(() => history.push('/merchant-office/my-deals'))
                  }
                >
                  {getText('submitForReview')}
                </Button>
              </Col>
            </Row>
          ) : (
            <div className={previewOptions(theme)}>
              <label>{getText('dealUnderRevision')}</label>
            </div>
          ))}
        {isMerchant && !isInPreview && (
          <>
            <Row className={merchantOptions(theme)}>
              <Col span={12} lg={12}>
                <label className={statusLabel}>
                  {getText('status')}: <span className="bold-text">{startCase(dealStatusInverse[deal.statusId])}</span>
                </label>
              </Col>
              <Col span={12} lg={12} className={merchantButtons}>
                {!isMobile && (
                  <StatusControlButtons
                    dealId={deal.id}
                    isMobile={false}
                    statusId={deal.statusId}
                    closeDealModal={setCloseDealModalVisible}
                  />
                )}
                {deal.statusId === dealStatus.approved && (
                  <Button type="info" small onClick={showHidePromotionsModal} className={mr({ lg: 10 })}>
                    {getText('promoteDeal')}
                  </Button>
                )}
                {isMobile && (
                  <Popover
                    content={
                      <StatusControlButtons
                        dealId={deal.id}
                        isMobile
                        statusId={deal.statusId}
                        closeDealModal={setCloseDealModalVisible}
                      />
                    }
                    overlayClassName={popoverWrapper(theme)}
                    zIndex={900}
                    placement="bottomRight"
                    trigger="click"
                  >
                    <FeatherIcon icon="more-vertical" size={28} />
                  </Popover>
                )}
              </Col>
            </Row>
            <PromotionsModal
              isModalVisible={isPromotionsModalVisible}
              showHideModal={showHidePromotionsModal}
              dealId={deal.id}
            />
          </>
        )}
        <Row>
          <Col>
            <Breadcrumbs items={dealDetailsBreadcrumbs(deal, dealDetails)} />
            {deal.statusId === dealStatus.soldOut && <span className={statusTag(theme)}>{getText('soldOut')}</span>}
            {deal.statusId === dealStatus.expired && <span className={statusTag(theme)}>{getText('expired')}</span>}
            <h1 className="section-title">{getDynamicTranslation(deal.dealDetails)?.title}</h1>
            {/* Deal top info section */}
            <div className={dealDetailsWrapper}>
              <Modal
                wrapClassName={galleryModal(theme)}
                visible={isModalVisible}
                footer={null}
                destroyOnClose
                width={1300}
                onCancel={() => setIsModalVisible(false)}
              >
                <h1 className={imgModalTitle}>{deal.title}</h1>
                <div className={dealDetailsWrapper}>
                  <div className={userContainer}>
                    <label className={merchantNameLbl(theme)}>{deal.merchantName}</label>
                    <Button
                      type="link"
                      small
                      linkTo={`/merchant/${deal.businessId}/overview`}
                      className={blackTextLink(theme)}
                    >
                      {businessName?.name}
                    </Button>
                    <img src={TickIcon} className={mr({ lg: 12, md: 12, sm: 12 })} alt="tick" />
                  </div>
                  <div className={`deal-rating-wrapper ${dealRatingWrapper(theme)} ${mr({ lg: 12, md: 12, sm: 12 })}`}>
                    <Rate className={starRatings(theme)} value={deal.rating} disabled />
                    <label className={ratingsCountLabel(theme)}>{Number(deal.rating.toFixed(1))} of 5 |</label>
                    {deal.reviewsCount > 0 ? (
                      <a
                        className={`${textUnderline} ${ratingsCountLabel(theme)}`}
                        href={`${location.pathname}/reviews`}
                      >
                        {deal.reviewsCount}
                      </a>
                    ) : (
                      <label className={ratingsCountLabel(theme)}>{deal.reviewsCount}</label>
                    )}
                  </div>
                  <div className={dealEndDateWrapper(theme)}>
                    <span className="icon-Ends-on" />
                    <label>{getText('dealEndsOn')} </label>
                    <label className={`${textFormat} ${name(theme)} ${mr({ lg: 12, md: 12, sm: 12 })}`}>
                      {formatDefaultDate(deal.dealEndDate)}
                    </label>
                  </div>
                </div>
                <ImageGalleryPanel startIndex={mediaIndexForPreview} showNav items={dealImages} />
              </Modal>
              <div className={userContainer}>
                <DealDetailsSeeOnMapAddresses merchantAddressesList={merchantAddressesList} />
                <span className={merchantNameLbl(theme)}>{deal.merchantName}</span>
                <Button
                  type="link"
                  small
                  linkTo={`/merchant/${deal.businessId}/overview`}
                  className={blackTextLink(theme)}
                >
                  {businessName?.name}
                </Button>
                <img src={TickIcon} className={mr({ lg: 12, md: 12, sm: 12 })} alt="tick" />
              </div>
              <div className={`deal-rating-wrapper ${dealRatingWrapper(theme)} ${mr({ lg: 12, md: 12, sm: 12 })}`}>
                <Rate className={starRatings(theme)} value={deal.rating} disabled />
                <label className={ratingsCountLabel(theme)}>{Number(deal.rating.toFixed(1))} of 5 |</label>
                {deal.reviewsCount > 0 ? (
                  <Link className={`${textUnderline} ${ratingsCountLabel(theme)}`} to={`${location.pathname}/reviews`}>
                    {deal.reviewsCount}
                  </Link>
                ) : (
                  <label className={ratingsCountLabel(theme)}>{deal.reviewsCount}</label>
                )}
              </div>
              <div className={dealEndDateWrapper(theme)}>
                <span className="icon-Ends-on" />
                <label>{getText('dealEndsOn')} </label>
                <label className={`${textFormat} ${name(theme)} ${mr({ lg: 12, md: 12, sm: 12 })}`}>
                  {formatDefaultDate(deal.dealEndDate)}
                </label>
              </div>
              {!!deal.bvPoints && (
                <p className={availableBvPoints(theme)}>{getText('bvPointsFromDeal', { points: deal.bvPoints })}</p>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={14} span={24}>
            <div className="relative">
              <ImageGalleryPanel
                items={dealImages}
                videosCount={countType(MediaTypesEnum.Video.id)}
                imagesCount={countType(MediaTypesEnum.Image.id)}
              />
              <div className={imageInfoOverlay}>
                {`${countType(MediaTypesEnum.Video.id) + countType(MediaTypesEnum.Link.id)} ${getText(
                  'video',
                )} | ${countType(MediaTypesEnum.Image.id)} ${getText('images')} `}
              </div>
            </div>
            <div className={`flex flex-center ${socialContainer}`}>
              {userInfo && userInfo?.id !== deal.merchantId && (
                <Button
                  type="link"
                  small
                  linkTo={`/chat?dealId=${deal.id}&userId=${deal.merchantId}`}
                  className={messagesLink(theme)}
                >
                  <FeatherIcon icon="message-square" size={10} className={mr({ lg: 5, md: 5 })} />
                  {getText('messageMerchant')}
                </Button>
              )}
              <FacebookShareButton url={window.location.href} className={facebookButton(theme)}>
                <span>
                  <span className="icon-facebook" />
                </span>
                <label>{getText('share')}</label>
              </FacebookShareButton>
            </div>
          </Col>
          <Col lg={{ offset: 1, span: 9 }} span={24}>
            <PricePanel deal={deal} onAddToShoppingCart={() => addDeal(deal)} className="DealDetails" />
          </Col>
        </Row>
        {/* Content section */}
        <Row className={contentRow}>
          <Col lg={19} span={24}>
            <DealContentPanel deal={deal} />
          </Col>
          <Col lg={{ offset: 1, span: 4 }} span={24}>
            <ConnectedDealsPanel deals={connectedDeals} dealId={deal.id} isMerchant={isMerchant} />
          </Col>
        </Row>
        <Row>
          <Col lg={19} span={24}>
            {reviewsList && reviewsSummary && (
              <ReviewsAndCommentsPanel
                reviewsSummary={reviewsSummary}
                reviewsList={reviewsList?.items}
                dealName={dealName}
                dealId={deal.id}
              />
            )}
          </Col>
        </Row>
      </section>
      <CloseConfirmationPopup
        isModalVisible={closeDealModalVisible}
        handleOk={handleCloseDealOk}
        handleCancel={() => setCloseDealModalVisible(false)}
      />
    </>
  );
};

export default DealDetailsPage;
