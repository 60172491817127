import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Menu } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import useError from 'services/errorHandling/useError';
import { getAllCategories } from 'services/api/category';
import { blackTextLink } from 'assets/css/globalCSS';
import { categoriesFilterContent, arrow } from './styles';

const CategoryFilter = ({ preSelectedCategoryId, categoryChangeHandler }) => {
  const { SubMenu } = Menu;
  const theme = useTheme();
  const { setError } = useError();
  const { getText } = useTranslations();
  const [allCategories, setAllCategories] = useState([]);
  const [openKeys, setOpenKeys] = useState([`${preSelectedCategoryId}`]);
  const [selectedCategory, setSelectedCategory] = useState([preSelectedCategoryId?.toString()] || []);

  const searchForParent = (array, string) => {
    const find = ({ id, subCategories }) => id === string || (subCategories && subCategories.some(find));
    return array.filter(find)[0];
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return setError(err);

    setAllCategories(res);
    searchForParent(res, Number(preSelectedCategoryId))
      ? setOpenKeys([searchForParent(res, Number(preSelectedCategoryId))?.id.toString(), preSelectedCategoryId])
      : setOpenKeys([preSelectedCategoryId]);
  };

  const handleSubMenuClick = key => {
    setOpenKeys(prevOpenKeys =>
      prevOpenKeys.includes(key) ? prevOpenKeys.filter(k => k !== key) : [...prevOpenKeys, key],
    );
  };

  const renderSubCategories = category => {
    if (!category.subCategories || category.subCategories.length === 0) return null;

    return category.subCategories.map(subCategory =>
      subCategory.subCategories && subCategory.subCategories.length > 0 ? (
        <SubMenu
          key={subCategory.id}
          title={
            <div className="flex space-between">
              <Button
                type="link"
                small
                className={blackTextLink(theme)}
                onClick={() => {
                  categoryChangeHandler(subCategory.id);
                  setSelectedCategory([subCategory.id.toString()]);
                }}
              >
                {getText(subCategory.name)}
              </Button>
              <Button
                type="link"
                small
                className={arrow(theme)}
                onClick={() => handleSubMenuClick(subCategory.id.toString())}
                theme={theme}
              >
                <span
                  className={`${openKeys.includes(subCategory.id.toString()) && 'isExpanded'} icon-SingleChevron`}
                />
              </Button>
            </div>
          }
        >
          {renderSubCategories(subCategory)}
        </SubMenu>
      ) : (
        <Menu.Item
          onClick={() => {
            categoryChangeHandler(subCategory.id);
            setSelectedCategory([subCategory.id.toString()]);
          }}
          key={subCategory.id}
        >
          {getText(subCategory.name)}
        </Menu.Item>
      ),
    );
  };

  return (
    <Menu
      className={categoriesFilterContent}
      selectedKeys={selectedCategory}
      defaultSelectedKeys={selectedCategory}
      openKeys={openKeys}
      mode="inline"
      triggerSubMenuAction="click"
      expandIcon={<></>}
    >
      {allCategories &&
        allCategories.map(category =>
          category.subCategories && category.subCategories.length > 0 ? (
            <SubMenu
              key={category.id}
              title={
                <div className="flex space-between">
                  <Button
                    type="link"
                    small
                    className={blackTextLink(theme)}
                    onClick={() => {
                      categoryChangeHandler(category.id);
                      setSelectedCategory([category.id.toString()]);
                    }}
                  >
                    {getText(category.name)}
                  </Button>
                  <Button
                    type="link"
                    small
                    className={arrow(theme)}
                    onClick={() => handleSubMenuClick(category.id.toString())}
                  >
                    <span
                      className={`${openKeys.includes(category.id.toString()) && 'isExpanded'} icon-SingleChevron`}
                    />
                  </Button>
                </div>
              }
            >
              {renderSubCategories(category)}
            </SubMenu>
          ) : (
            <Menu.Item key={category.id}>{getText(category.name)}</Menu.Item>
          ),
        )}
    </Menu>
  );
};

CategoryFilter.propTypes = {
  preSelectedCategoryId: PropTypes.string,
  categoryChangeHandler: PropTypes.func,
};

export default CategoryFilter;
