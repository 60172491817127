import React, { useRef } from 'react';
import ChatMiniPanel from 'components/layouts/HeaderPanel/components/ChatMiniPanel/ChatMiniPanel';
import { useUser } from 'stores';
import { AnonymousUserMiniProfile, NotificationsMiniPanel, UserMiniProfile, MiniShoppingCart } from './components';

const UserActionPanel = () => {
  const { isAuthenticated, isDisabled } = useUser();
  const userRef = useRef();

  return (
    <div className="flex flex-center">
      {isAuthenticated ? (
        <>
          {!isDisabled && (
            <>
              <ChatMiniPanel />
              <NotificationsMiniPanel />
            </>
          )}
          <UserMiniProfile />
        </>
      ) : (
        <AnonymousUserMiniProfile userRef={userRef} />
      )}
      {!isDisabled && <MiniShoppingCart onLoginClick={() => userRef?.current?.onClick()} />}
    </div>
  );
};

export default UserActionPanel;
