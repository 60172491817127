import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { categoryCardImages } from 'mappings/category';
import { stringify } from 'query-string';
import { Button } from 'components/UIExternal';
import { categoryCardTemplate } from './styles';

const CategoryCard = ({ category }) => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const query = stringify({ categoryId: category?.id });

  return (
    <Button
      type="link"
      className={categoryCardTemplate(theme, categoryCardImages[category.name])}
      linkTo={`/listing?${query}`}
    >
      <p className={category.name}>{getText(category.name)}</p>
    </Button>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.object,
};

export default CategoryCard;
