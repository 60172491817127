import { Desktop } from 'assets/css/breakpoints';
import { css } from 'emotion';

export const formSectionWrapper = theme =>
  css({
    '& .upload-field-type': {
      flexWrap: 'wrap',
      gap: '1em',
    },
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.25em 2em',
      marginBottom: '1.5em',
    },
  });

export const fileUploadBlock = css({
  [Desktop]: {
    '& > span': {
      display: 'flex',
      gap: '0.625em',
      margin: '2em 0',
    },
    '& .ant-upload-list-item': {
      margin: '0 0 0.5em 0',
    },
    '& .ant-upload.ant-upload-drag': {
      flex: 1,
    },
    '& .ant-upload-list.ant-upload-list-picture': {
      flex: 1.5,
    },
  },
});

export const title = css({
  fontSize: '1.125rem',
  fontWeight: 700,
  marginBottom: '1.25em',
});

export const filesContainer = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(15.625em, 2fr))',
  columnGap: '0.625em',
  margin: '1.25em 0',
  '& .upload-text': {
    marginTop: '0.75em',
  },
});

export const filesListStyles = css({
  listStyle: 'none',
  display: 'grid',
  rowGap: '0.3125em',
  maxHeight: '13.125rem',
  padding: '0.3125em',
  WebkitScrollbar: {
    width: '0.1875rem',
    height: '0.3125rem',
  },
  WebkitScrollbarThumb: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  overflowY: 'auto',
});

export const fileListItem = theme =>
  css({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
    background: theme.gray600,
    padding: '0.75em 0.375em',
    fontSize: '0.75rem',
    height: '3.75rem',
    '& svg': {
      gridColumnStart: '0.1875em',
      marginLeft: 'auto',
      marginRight: 0,
    },
    '& .file-name': {
      width: '13.75rem',
      color: theme.gray100,
    },
    '& .file-description': {
      width: '13.75rem',
      color: theme.gray300,
    },
  });

export const toggleHiddenText = css({
  width: '15.625rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  transition: 'all .5s ease-in-out',
  '&:hover': {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
});
