import { css } from 'emotion';
import styled from '@emotion/styled';
import { Mobile } from 'assets/css/breakpoints';

export const PublicMerchantPage = styled.div`
  background-color: ${props => props.theme.white};
  position: relative;

  & .gray-background {
    background-color: ${props => props.theme.gray600};
  }
  & > .content-container:not(:first-of-type) {
    padding-top: 0;
  }
  & > .content-container > .ant-tabs:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2.8125rem;
    background-color: ${props => props.theme.gray600};
  }
`;

export const title = theme =>
  css({
    color: theme.background100,
    fontSize: '1.4375rem',
    fontWeight: 700,
    marginBottom: 0,
    marginRight: '0.5em',
  });

export const titleWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  [Mobile]: {
    flexDirection: 'column',
    '& a.ant-btn': {
      paddingLeft: '0px !important',
      justifyContent: 'flex-start !important',
    },
  },
});

export const countryLabel = theme =>
  css({
    display: 'inline-block',
    fontSize: '0.75rem',
    color: theme.gray200,
    marginLeft: '0.3125em',
    marginBottom: '0.9375em',
  });
