import React, { useState, useRef } from 'react';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import { isNil } from '@veraio/core';
import FeatherIcon from 'feather-icons-react';
import { MerchantAddressMap } from 'components/shared';
import { Button, Modal } from 'components/UIExternal';
import useError from 'services/errorHandling/useError';
import { seeMeOnMap, modalContainer } from './styles';

const animateConfig = { animate: true, duration: 1.4 };

export const DealDetailsSeeOnMapAddresses = ({ merchantAddressesList }) => {
  const theme = useTheme();
  const { setError } = useError();
  const [userCoords, setUserCoords] = useState(null);
  const mapRef = useRef();
  const modalRef = useRef();

  const handleOpenModal = () => {
    modalRef.current?.open();
    fetchBusinessAddresses();
  };

  const fetchBusinessAddresses = async () => {
    let userCoordinates = userCoords;
    if (isNil(userCoords)) userCoordinates = await getUserCoordinates();

    // When open modal it should fly to addresses of the business
    // We need the setTimeout because mapRef is still not initialized because it is inside modal
    // There is problem with this createPortal and references, they are initialized after the call of useEffect
    setTimeout(() => {
      flyToAddresses(merchantAddressesList, userCoordinates);
    });
  };

  const getUserCoordinates = () =>
    new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(
        event => {
          const coords = { lat: event.coords.latitude, lng: event.coords.longitude };
          setUserCoords(coords);
          resolve(coords);
        },
        error => {
          resolve();
          setUserCoords(undefined);
          setError(error);
        },
      );
    });

  const flyToAddresses = (addresses, userCoordinates) => {
    const boundaries = addresses?.map(el => [el.latitude, el.longitude]);
    boundaries?.length
      ? mapRef.current?.flyToBounds(boundaries, animateConfig)
      : mapRef.current?.flyTo(userCoordinates ?? { lat: 0, lng: 0 }, 17, animateConfig);
  };

  return (
    <>
      {!!merchantAddressesList.length && (
        <Button small type="link" onClick={handleOpenModal} className={seeMeOnMap(theme)}>
          <FeatherIcon icon="map" size={20} stroke={theme.gray100} />
          <span>{getText('seeOnMap')}</span>
        </Button>
      )}
      <Modal large ref={modalRef} className={modalContainer}>
        <MerchantAddressMap mapRef={mapRef} merchantAddressesList={merchantAddressesList} userPosition={userCoords} />
      </Modal>
    </>
  );
};
