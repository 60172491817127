import { css } from 'emotion';

export const modalContainer = css({
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  overflow: 'hidden auto',
  display: 'block',
});

export const modalScrollContainer = ({ large, small }) =>
  css([
    {
      minHeight: 'calc(100vh - 56px)',
      maxWidth: '31.25rem',
      margin: '1.75em auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    large && {
      '@media (min-width: 992px)': {
        maxWidth: 800,
      },
    },
    small && {
      '@media (min-width: 576px)': {
        maxWidth: '18.75rem',
      },
    },
  ]);

export const modalContentContainer = theme =>
  css({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.white,
    padding: '1.5em',
    borderRadius: 8,
  });

export const headerContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '1em',
});

export const closeIconStyles = css({
  margin: '0 0 0 auto',
  marginBottom: '2em',
  cursor: 'pointer',
  position: 'absolute',
  top: 5,
  right: 5,
  zIndex: 1000,
});
