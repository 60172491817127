import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations, getDynamicTranslation } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Col, Row } from 'antd';
import { formatDefaultDate } from 'utils/valueFormatter';
import { Button } from 'components/UIExternal';
import { mr } from 'assets/css/globalCSS';
import { deliveryMethodSelect } from 'enums';
import TickIcon from 'assets/images/icn-tick-filled.svg';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import {
  // REMOVE TAG: Hide Coupons Details
  // couponTemplate,
  couponThumbnail,
  couponTitle,
  StarRatings,
  ratingsCountLabel,
  ratingsCountLink,
  CouponDescription,
  dealEndDateWrapper,
  buyerInfoContainer,
  merchantInfo,
  thumbnailContainer,
  titleWrapper,
  couponLabel,
} from './styles';

const OrderSummaryCoupon = ({ coupon, isInRefundForm, merchantId }) => {
  const theme = useTheme();
  const { getText } = useTranslations();

  const {
    dealId,
    id,
    name,
    media,
    title,
    businessName,
    businessId,
    rating,
    reviewsCount,
    description,
    deliveryMethodId,
    endDate,
    coupons,
  } = coupon;

  return (
    // REMOVE TAG: Hide Coupons Details
    // <Link to={`/coupon-details/${dealId || id}`} className={couponTemplate}>
    <Row>
      <Col lg={6} span={24}>
        <div className={thumbnailContainer}>
          <img className={couponThumbnail} src={media?.url || placeholderThumbnail} alt="Coupon" />
        </div>
      </Col>
      <Col lg={15} span={24}>
        <div className={`flex-column flex-1 ${titleWrapper}`}>
          <p className={couponTitle}>{getDynamicTranslation(title)?.title} </p>
          <p className={`flex ${merchantInfo}`}>
            <label>
              <Link to={`/merchant/${businessId}/overview`}>{businessName}</Link>
            </label>
            <img src={TickIcon} className={mr({ lg: 12 })} alt="tick" />
            <div className="separator" />
            <StarRatings theme={theme} value={rating} disabled />
            {reviewsCount > 0 ? (
              <Link className={ratingsCountLink(theme)} to={`/deal/${name}/reviews`}>
                {reviewsCount}
              </Link>
            ) : (
              <label className={ratingsCountLabel(theme)}>{reviewsCount}</label>
            )}
          </p>
          <CouponDescription title={description}>{description}</CouponDescription>
          <div>
            <p className={dealEndDateWrapper}>
              <span className="icon-Ends-on" />
              <label>{getText('endsOn')} </label>
              <label className={`${mr({ lg: 12 })} bold`}>{formatDefaultDate(endDate)}</label>
              <span className={`delivery-icon ${deliveryMethodSelect[deliveryMethodId].icon}`} />
              <label>{getText(deliveryMethodSelect[deliveryMethodId].label)}</label>
            </p>
          </div>
          {isInRefundForm && (
            <label className={couponLabel(theme)}>
              {getText('coupon')}
              <span>{coupons[0]?.code}</span>
            </label>
          )}
        </div>
      </Col>
      {!isInRefundForm && (
        <Col lg={3} span={24}>
          <div className={buyerInfoContainer}>
            <Button type="link" small linkTo={`/chat?dealId=${dealId || id}&userId=${merchantId}`}>
              <FeatherIcon icon="message-square" size={12} />
              {getText('messageMerchant')}
            </Button>
          </div>
        </Col>
      )}
    </Row>
    // REMOVE TAG: Hide Coupons Details
    // </Link>
  );
};

OrderSummaryCoupon.propTypes = {
  coupon: PropTypes.object,
  isInRefundForm: PropTypes.bool,
  merchantId: PropTypes.string,
};

export default OrderSummaryCoupon;
