import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isTokenValid } from '@oneecosystem/authenticate';
import FeatherIcon from 'feather-icons-react';
import { Rate } from 'antd';
import { Button } from 'components/UIExternal';
import { deliveryMethodSelect } from 'enums';
import useError from 'services/errorHandling/useError';
import { formatDescriptiveDate } from 'utils';
import { addDealToWishlist, deleteDealFromWishlist } from 'services/api/wishlist';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import { ml, blackTextLink } from 'assets/css/globalCSS';
import { DealPrice } from 'components/shared';
import DiscountStamp from '../DiscountStamp';

import {
  ThumbnailContainer,
  Thumbnail,
  dealDiscountStamp,
  favoriteIcon,
  dateAvailableLabel,
  starRatings,
  ratingsCountLabel,
  ratingsCountLink,
  TitleLabel,
  dealWrapper,
  titleIconWrapper,
  categoryRatingWrapper,
  categoryLabel,
} from './styles';

const Deal = ({ deal, dataId }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const isLoggedIn = isTokenValid();
  const { id, name, media, discount, isInWishList, dateAvailable, dealCategory, rating, reviewsCount } = deal;
  const [addedToWishlist, setAddedToWishlist] = useState(isInWishList);
  const [wishlistBtnDisabled, setWishlistBtnDisabled] = useState(!isLoggedIn);

  const handleDealWishList = async wishListRequest => {
    const [, err] = await wishListRequest(id);
    err ? setError(err) : setAddedToWishlist(prev => !prev);
    setWishlistBtnDisabled(false);
  };

  const manageWishlist = e => {
    e.preventDefault();
    setWishlistBtnDisabled(true);

    addedToWishlist ? handleDealWishList(deleteDealFromWishlist) : handleDealWishList(addDealToWishlist);
  };

  const thumbnailUrl =
    media?.typeId === 3 ? `https://img.youtube.com/vi/${media?.url}/0.jpg` : media?.url || placeholderThumbnail;

  return (
    <div className={ml({ lg: 10, md: 10 })}>
      <Button type="link" key={id} className={dealWrapper} linkTo={`/deal/${name}`}>
        <ThumbnailContainer className="deal-thumbnail-container cloned-thumbnail-container">
          <Thumbnail alt="Deal" src={thumbnailUrl} className="thumbnail" />

          {discount > 0 && <DiscountStamp deal={deal} className={dealDiscountStamp} />}
          <Button
            type="link"
            small
            disabled={wishlistBtnDisabled}
            className={favoriteIcon(theme)}
            onClick={e => manageWishlist(e)}
          >
            {!addedToWishlist ? (
              <FeatherIcon icon="heart" size={17} stroke={theme.gray300} />
            ) : (
              <FeatherIcon icon="heart" size={17} stroke={theme.white} fill={theme.red} />
            )}
          </Button>
        </ThumbnailContainer>
        <div className={titleIconWrapper(theme)}>
          <TitleLabel>{getDynamicTranslation(deal?.dealDetails)?.title}</TitleLabel>
          <span className={deliveryMethodSelect[deal.deliveryMethodId]?.icon} />
        </div>
        {dateAvailable && (
          <p className={dateAvailableLabel(theme)}>
            {getText('dealAvailableOn', { date: formatDescriptiveDate(dateAvailable) })}
          </p>
        )}
        <DealPrice deal={deal} className={blackTextLink(theme)} dataId={`${dataId}-deal-price-panel`} />
      </Button>
      <div className={categoryRatingWrapper}>
        <span className={categoryLabel}>{getText(dealCategory)}</span>
        <div className="separator" />
        <Rate className={starRatings(theme)} value={rating} disabled />
        {reviewsCount > 0 ? (
          <Button type="link" className={ratingsCountLink(theme)} linkTo={`/deal/${name}/reviews`}>
            {reviewsCount}
          </Button>
        ) : (
          <span className={ratingsCountLabel(theme)}>{reviewsCount}</span>
        )}
      </div>
    </div>
  );
};

Deal.propTypes = {
  deal: PropTypes.object,
  dataId: PropTypes.string,
};

export default Deal;
