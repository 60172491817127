import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useParams } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Rate } from 'antd';
import { round } from 'exact-math';
import ListingDeal from 'components/deal/ListingDeal';
import { Pagination, Button } from 'components/UIExternal';
import useError from 'services/errorHandling/useError';
import starBoxImg from 'assets/images/addDeal/star-box.svg';
import { getBusinessOverview } from 'services/api/businessesService';
import { getBusinessReviewsSummary } from 'services/api/reviewService';
import { getDealsPublicProfile } from 'services/api/dealsService';
import { mb, mr } from 'assets/css/globalCSS';
import {
  publicMerchantTab,
  merchantInfoWrapper,
  icon,
  merchantInfoText,
  dealsAddressesWrapper,
  dealsAddressesDetails,
  ratingsContainer,
  ratingWrapper,
  dealsTitle,
  linkButtonStyles,
} from './styles';

const OverviewTab = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { setError } = useError();
  const { businessId } = useParams();
  const [recentDeals, setRecentDeals] = useState(null);
  const [businessOverview, setBusinessOverview] = useState(null);
  const [businessReviewsSummary, setBusinessReviewsSummary] = useState(null);

  useEffect(() => {
    fetchBusinessOverview();
    fetchBusinessReviewsSummary();
  }, []);

  const fetchDeals = async options => {
    const params = { ...options, dealsSorting: 0 };
    const [res, err] = await getDealsPublicProfile(businessId, params);
    err ? setError(err) : setRecentDeals(res);
    return res;
  };

  const fetchBusinessOverview = async () => {
    const [res, err] = await getBusinessOverview(businessId);
    err ? setError(err) : setBusinessOverview(res);
  };

  const fetchBusinessReviewsSummary = async () => {
    const [res, err] = await getBusinessReviewsSummary(businessId);
    err ? setError(err) : setBusinessReviewsSummary(res);
  };

  return (
    <div className={publicMerchantTab(theme)}>
      <Row style={{ marginTop: 24 }} gutter={[24, 24]}>
        <Col className="gutter-row" lg={6} span={24}>
          <div className={merchantInfoWrapper(theme)}>
            <div className={`flex ${mb({ lg: 16, span: 16 })}`}>
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Email" />
              </span>
              <p className={merchantInfoText(theme)}>
                <label>{getText('emailAddress')}:</label>
                <br />
                {businessOverview?.email ? businessOverview.email : '-'}
              </p>
            </div>
            <div className={`flex ${mb({ lg: 16, span: 16 })}`}>
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Phone" />
              </span>
              <p className={merchantInfoText(theme)}>
                <label>{getText('phoneNumber')}:</label>
                <br />
                {businessOverview?.phone ? businessOverview.phone : '-'}
              </p>
            </div>
            <div className="flex">
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Location" />
              </span>
              <p className={merchantInfoText(theme)}>
                <label>{getText('postCode')}:</label>
                <br />
                {businessOverview?.postCode ? businessOverview.postCode : '-'}
              </p>
            </div>
          </div>
        </Col>
        <Col className="gutter-row" lg={9} span={24}>
          <div className={dealsAddressesWrapper(theme)}>
            <img src={starBoxImg} alt="Star box" />
            <div className={dealsAddressesDetails(theme)}>
              <p>{getText('activeDeals')}</p>
              <label>{businessOverview?.activeDealsCount}</label>
              <Button type="link" linkTo={`/merchant/${businessId}/deals`} className={linkButtonStyles}>
                {getText('browseAllDeals')}
              </Button>
            </div>
          </div>
        </Col>
        <Col className="gutter-row" lg={9} span={24}>
          <div className={dealsAddressesWrapper(theme)}>
            <img src={starBoxImg} alt="Star box" />
            <div className={dealsAddressesDetails(theme)}>
              <p>{getText('merchantAddresses')}</p>
              <label>{businessOverview?.addressesCount}</label>
              <Button type="link" className={linkButtonStyles} linkTo={`/merchant/${businessId}/merchant-profile`}>
                {getText('seeAddresses')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }} className={mb({ lg: 32, span: 30 })} gutter={[24, 24]}>
        <Col className="gutter-row" lg={6} span={24}>
          <div className={ratingsContainer(theme)}>
            <div className={ratingWrapper(theme)}>
              <label className="rating">{round(businessReviewsSummary?.lastReviewsRating ?? 0, -2)}</label>
              <label className="description">{getText('lastDealsCount', { count: 10 })}</label>
              <Rate value={businessReviewsSummary?.lastReviewsRating} disabled />
            </div>
            <div className={ratingWrapper(theme)}>
              <label className="rating">{round(businessReviewsSummary?.rating ?? 0, -2)}</label>
              <label className="description">{getText('average')}</label>
              <Rate value={businessReviewsSummary?.rating} disabled />
            </div>
          </div>
        </Col>
        <Col className="gutter-row" lg={18} span={24}>
          <h6 className={dealsTitle(theme)}>{getText('latestDeals')}</h6>
          {recentDeals?.items?.map((deal, idx) => (
            <ListingDeal key={idx} deal={deal} mainDealName={deal.name} isMerchant={deal?.isOwner} />
          ))}
          <Pagination onChange={fetchDeals} pageSize={5} />
        </Col>
      </Row>
    </div>
  );
};

export default OverviewTab;
