import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Rate } from 'antd';
import { Button } from 'components/UIExternal';
import { useTheme } from 'emotion-theming';
import { merchantBusinessInfoCard } from './styles';

export const BusinessCard = ({ merchant, onClick, isSelected }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();

  return (
    <Button type="link" onClick={() => onClick(merchant)} className={merchantBusinessInfoCard(isSelected, true, theme)}>
      <div className="business-header">
        <h3 className="business-name">{getDynamicTranslation(merchant?.title)?.name}</h3>
        <span className="separator" />
        <Rate value={merchant?.reviewsRating} className="business-rating" disabled allowHalf />
        <span className="business-rating-count">{merchant?.reviewsCount}</span>
      </div>
      <p className="business-active-deals-count">
        {getText('activeDealsCount', { activeDeals: merchant?.activeDeals })}
      </p>
      {!!merchant?.items?.filter(el => el.media)?.length && (
        <div className="business-images">
          {merchant?.items.map((el, idx) => (
            <img src={el?.media?.url} alt={el?.media?.name} key={idx} className="business-image" />
          ))}
        </div>
      )}
      {!!merchant?.address?.trim()?.length && <p className="business-address">{merchant?.address}</p>}
      {!!merchant?.workingTime?.trim()?.length && <p className="working-time">{merchant?.workingTime}</p>}
    </Button>
  );
};

BusinessCard.propTypes = {
  merchant: PropTypes.object,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export * from './BusinessCardSkeleton';
