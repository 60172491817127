import { css } from 'emotion';

export const categoriesFilterContent = css({
  width: '100%',
});
export const arrow = theme =>
  css({
    color: theme.gray100,
    padding: '0 !important',
    '& .isExpanded': { transform: 'rotate(90deg)' },
    '& .icon-SingleChevron': { fontSize: '1.5625rem' },
  });
