import { css } from 'emotion';
import styled from '@emotion/styled';

export const dealWrapper = css({
  fontWeight: 400,
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0.625em 0',
  minWidth: '11.875rem',
  minHeight: '13.875rem',
  display: 'block',
});

export const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 57%;
  overflow: hidden;
  border-radius: 0.25em;
  margin-bottom: 0.625em;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
`;

export const dealDiscountStamp = css({
  top: '0.5em',
  left: '0.5em',
  height: '2.5rem',
  width: '2.5rem',
});

export const thumbnailTag = css({
  position: 'absolute',
  bottom: '0.25rem',
  left: '0.25rem',
});

export const favoriteIcon = theme =>
  css({
    color: theme.white,
    '& svg': {
      position: 'absolute',
      bottom: '0.3125em',
      right: '0.3125em',
    },
  });

export const titleIconWrapper = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '0.75em',
    color: theme.gray100,
    '& span': {
      fontSize: '1.4375rem',
      color: theme.gray200,
      marginLeft: '0.3125em',
      marginTop: '-0.25em',
    },
  });

export const dateAvailableLabel = theme =>
  css({
    color: theme.primary_orange,
    fontSize: '0.75rem',
  });

export const starRatings = theme =>
  css({
    '&.ant-rate': {
      color: theme.gray100,
      fontSize: '0.75rem',
    },
    marginRight: '0.625em',
    display: 'inherit',
  });

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125em',
    color: theme.gray300,
    textDecoration: 'underline',
    padding: 0,
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    minHeight: '2.5em',
    lineHeight: 3.1,
  });

export const categoryLabel = css({
  fontSize: '0.75rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const TitleLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 0.375em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  cursor: pointer;
`;

export const categoryRatingWrapper = css({
  display: 'flex',
  alignItems: 'center',
  minHeight: '2.5rem',
});
