import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import BusinessStatus from 'enums/BusinessStatus';
import { addressBox, topContentWrapper, addressTitle, infoTextWrapper } from './styles';

const PublicAddressCard = ({ address }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();

  return (
    <>
      <div className={addressBox(theme)}>
        <div className={topContentWrapper(theme)}>
          <div className={addressTitle(theme)}>
            <h6>{getDynamicTranslation(address.businessAddressDetails)?.name}</h6>
          </div>
          <label>{`${getText('status')}: ${BusinessStatus.getById(address.statusId).value}`}</label>
        </div>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('assignMerchantProfiles')}:</label>
          <br />
          {getDynamicTranslation(address.businessNames)?.name}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('country')}:</label>
          <br />
          {address.country}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('city')}:</label>
          <br />
          {address.city}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('stateProvince')}:</label>
          <br />
          {address.state}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('postcode')}:</label>
          <br />
          {address.postcode}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('streetAddress')}:</label>
          <br />
          {address.street}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('contactPerson')}:</label>
          <br />
          {address.contactPerson}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('merchantPhoneNumber')}:</label>
          <br />
          {address.phoneNumber}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('workDays')}:</label>
          <br />
          {address.workingDays}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('workingHours')}:</label>
          <br />
          {address.workingHours}
        </p>
      </div>
    </>
  );
};

PublicAddressCard.propTypes = {
  address: PropTypes.object,
};

export default PublicAddressCard;
