import { css } from 'emotion';
import { commonStyles } from '../../ui/CommonStyles';

export const searchBarContainer = props => [
  {
    position: 'relative',
    zIndex: 50,
    width: '100%',
  },
  props.className,
  ...commonStyles(props),
];

export const searchBarInputContainer = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.gray500}`,
    width: '100%',
  });

export const searchBarInput = css({
  lineHeight: 1.2,
  outline: 'none',
  padding: '0.5em 0.7em',
  border: 'none',
  background: 'transparent',
  flex: 1,
  '&:focus, &:active': {
    border: 'none',
    boxShadow: 'none',
  },
});

export const searchBarInputIcon = css({
  cursor: 'text',
  fontSize: '1rem',
  padding: '0.2em 0.4em 0 0',
});

export const searchBarSuggestionsContainer = theme =>
  css({
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 0,
    right: 0,
    color: theme.gray200,
    maxHeight: '18.75rem',
    zIndex: 20,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: theme.white,
    boxShadow: theme.boxShadow,
  });

export const searchBarSuggestionItem = theme =>
  css({
    padding: '0.8em 1.5em',
    color: theme.gray200,
    '&:focus, &:hover': {
      outline: 'none',
      backgroundColor: theme.primary,
      color: theme.gray300,
    },
  });
