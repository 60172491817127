import queryString from 'query-string';
import { isNumber, pick, isNil, isEmpty } from '@veraio/core';
import { calculateDealPrice } from '@oneecosystem/dealshaker-core';
import { validDate } from 'utils';

export const dealListingParams = options => {
  const { searchText, dealsSorting } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(dealsSorting) && { dealsSorting }),
      ...(searchText && searchText?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};

export const merchantDealsParams = options => {
  const { businessId, statusId, searchText, dealsSorting } = options || {};
  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(businessId) && { businessId }),
      ...(isNumber(statusId) && { statusId }),
      ...(isNumber(dealsSorting) && { dealsSorting }),
      ...(searchText && searchText?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};

export const randomDealsParams = options => {
  const { promotionType, from, to } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(promotionType) && { promotionType }),
      ...(!isNil(from) && { from }),
      ...(!isNil(to) && { to }),
    },
    { arrayFormat: 'index' },
  );
};

export const bestSellingDealsParams = options => {
  const { from, to } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(!isNil(from) && { from }),
      ...(!isNil(to) && { to }),
    },
    { arrayFormat: 'index' },
  );
};

export const promoDealsParams = options => {
  const { from, to } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(!isNil(from) && { from }),
      ...(!isNil(to) && { to }),
    },
    { arrayFormat: 'index' },
  );
};

export const basedOnInterestsDealsParams = options => {
  const { from, to } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(!isNil(from) && { from }),
      ...(!isNil(to) && { to }),
    },
    { arrayFormat: 'index' },
  );
};

export const searchParams = options => {
  const { dealTitle, tags, languageCode } = options;
  return queryString.stringify(
    {
      ...(!isNil(dealTitle) && { dealTitle }),
      ...(!isNil(tags) && { tags }),
      ...(!isNil(languageCode) && { languageCode }),
    },
    { arrayFormat: 'index' },
  );
};

export const getMerchantDealsInfoParams = options => {
  const { businessId, statusIds } = options;
  return queryString.stringify(
    {
      ...(isNumber(businessId) && { businessId }),
      ...(!isEmpty(statusIds) && { statusIds }),
    },
    { arrayFormat: 'index' },
  );
};

export const connectedDealsParams = options => {
  const { dealsSorting } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(dealsSorting) && { dealsSorting }),
      ...(isNumber() && {}),
    },
    { arrayFormat: 'index' },
  );
};

export const getAvailableDealsToConnectParams = options => {
  const { searchText } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(searchText && searchText?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};

export const getAllAvailableDealsBody = res => ({
  ...res,
  availabledeals: res.availabledeals.map(el => calculateDealPrice(el)),
});

export const getDealListingParams = options => {
  const { searchText, dealsSorting } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(dealsSorting) && { dealsSorting }),
      ...(searchText && searchText?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};

export const dealListingResponse = res => ({ ...res, items: res.items?.map(calculateDealPrice) });

export const dealListingAvailableDealsResponse = res => ({
  ...res,
  availabledeals: res?.availabledeals?.map(calculateDealPrice),
});

export const dealsCategoryFeaturedParams = options => {
  const { categoryId, from, to } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(categoryId) && { categoryId }),
      ...(!isNil(from) && { from }),
      ...(!isNil(to) && { to }),
    },
    { arrayFormat: 'index' },
  );
};

export const getCreateDealStepDataResponse = (stepNumber, res) =>
  stepNumber === 2 ? { ...res, startDate: validDate(res.startDate), endDate: validDate(res.endDate) } : res;
