import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, AutoComplete, Spin } from 'antd';
import { useTheme } from 'emotion-theming';
import { pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Pagination, useUrlParams } from 'components/UIExternal';
import { SearchInput } from 'components/ui';
import CommentReview from 'components/review/CommentReview';
import ReviewsSummary from 'components/review/ReviewsSummary';
import { searchDropdown, commentsWrapper } from 'screens/Private/MerchantOffice/components/styles';
import { getBusinessesDealFromSearch } from 'services/api/dealsService';
import {
  getDealReviewsSummaryWithLastReviews,
  getBusinessReviewsSummary,
  getDealReviewsList,
} from 'services/api/reviewService';
import useError from 'services/errorHandling/useError';
import { mb } from 'assets/css/globalCSS';
import { publicMerchantTab, ratingsTitleWrapper } from './styles';

const filterKeys = {
  deal: 'dealId',
  stars: 'stars',
};

const RatingsTab = () => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const { queryParams, setQueryParams, pathParams } = useUrlParams();
  const [dealReviewsList, setDealReviewsList] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [businessReviewsSummary, setBusinessReviewsSummary] = useState(null);
  const [dealReviewsSummary, setDealReviewsSummary] = useState(null);
  const paginationRef = useRef();
  const { businessId } = pathParams;
  const matchedDeal = searchResult?.find(el => el.id === queryParams[filterKeys.deal]);

  useEffect(() => {
    fetchDealForSearch();
  }, []);

  const fetchDealForSearch = async text => {
    const [res, err] = await getBusinessesDealFromSearch(businessId, text ?? null);
    if (err) return setError(err);

    setSearchResult(res.items);
    const preselectedDeal = res.items?.find(el => el.id === queryParams[filterKeys.deal]);
    preselectedDeal ? fetchDealReviewsSummary(preselectedDeal?.id) : fetchBusinessReviewsSummary();
  };

  const fetchBusinessReviewsSummary = async () => {
    const [res, err] = await getBusinessReviewsSummary(businessId);
    err ? setError(err) : setBusinessReviewsSummary(res);
  };

  const fetchDealReviewList = async options => {
    const [res, err] = await getDealReviewsList(options);
    paginationRef.current?.changeTotalResults(res);
    err ? setError(err) : setDealReviewsList(res);
  };

  const fetchDealReviewsSummary = async dealId => {
    const [res, err] = await getDealReviewsSummaryWithLastReviews(dealId);
    err ? setError(err) : setDealReviewsSummary(res);
  };

  const onSelect = value => {
    const selectedDeal = searchResult.find(el => getDynamicTranslation(el.dealDetails)?.title === value);
    setQueryParams({ [filterKeys.deal]: selectedDeal?.id });
    fetchDealReviewsSummary(selectedDeal?.id);
  };

  return (
    <div className={publicMerchantTab(theme)}>
      <Row className={ratingsTitleWrapper}>
        <Col lg={17} span={24}>
          {matchedDeal && <h5>{matchedDeal?.title}</h5>}
        </Col>
        <Col lg={{ offset: 2, span: 5 }} span={24} className={searchDropdown}>
          <AutoComplete
            notFoundContent={getText('noResultsFound')}
            options={searchResult?.map(el => {
              const value = getDynamicTranslation(el.dealDetails)?.title;
              return { value, label: value };
            })}
            onSelect={onSelect}
            value={matchedDeal?.title}
          >
            <SearchInput value={matchedDeal?.title} onPressEnter={onSelect} placeholder={getText('searchForDeals')} />
          </AutoComplete>
        </Col>
      </Row>
      <Row className={mb({ lg: 50, span: 30 })}>
        <Col span={24}>
          {dealReviewsSummary ? (
            <ReviewsSummary
              isInPublicMerchant
              reviewsAndComments={dealReviewsSummary}
              setStarsFilter={stars => paginationRef.current?.applyFilters({ stars })}
              dealName={matchedDeal?.title}
            />
          ) : businessReviewsSummary ? (
            <ReviewsSummary
              isInPublicMerchant
              isOverview
              reviewsAndComments={businessReviewsSummary}
              dealName={matchedDeal?.title}
            />
          ) : (
            <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
              <Spin size="large" />
            </div>
          )}
          {matchedDeal && (
            <>
              {dealReviewsList && (
                <div className={commentsWrapper(theme)}>
                  <label>{getText('commentsCount', { count: dealReviewsList.totalCount })}</label>
                  {dealReviewsList.items.map((item, ind) => (
                    <CommentReview reviews={item} key={ind} />
                  ))}
                </div>
              )}
              <Pagination
                ref={paginationRef}
                justify="center"
                pageSize={10}
                onChange={fetchDealReviewList}
                filterKeys={filterKeys}
                requiredFilterKeys={pick(filterKeys, 'deal')}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RatingsTab;
