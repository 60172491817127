import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isNil, dropRight } from 'lodash-es';
import { Upload, Input } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { toast } from 'react-hot-toast';
import { Button } from 'components/UIExternal';
import { merchantFileType } from 'enums/UploadFilesType';
import useError from 'services/errorHandling/useError';
import { removeMedia, uploadFiles } from 'services/api/mediaService';
import { ml, mr } from 'assets/css/globalCSS';
import {
  formSectionWrapper,
  fileUploadBlock,
  filesListStyles,
  fileListItem,
  filesContainer,
  toggleHiddenText,
  title,
} from './styles';

const UploadFiles = ({ setMedia, mediaFiles }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [fileInfo, setFileInfo] = useState({ file: null, description: null, isUploaded: null });
  const [fileList, setFileList] = useState(mediaFiles ?? []);
  const { Dragger } = Upload;

  const fileUploadConfig = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: merchantFileType.allowedFormat.join(','),
    beforeUpload: file => fileValidation(file),
  };

  const fileValidation = file => {
    if (file.size > merchantFileType.sizeLimit) {
      toast.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
      return Upload.LIST_IGNORE;
    }

    if (!merchantFileType.allowedFormat.includes(file.type)) {
      toast.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
      return Upload.LIST_IGNORE;
    }

    setFileInfo(prev => ({ ...prev, file, isUploaded: true }));
  };

  const updateData = (prop, value) => setFileInfo(prev => ({ ...prev, [prop]: value }));

  const handleUpload = async () => {
    const fileData = new FormData();
    fileData.append('description', fileInfo.description);
    fileData.append('file', fileInfo.file);
    const [res, err] = await uploadFiles(fileData);
    err && setError(err);
    !isNil(res) && setFileList(prev => [...prev, res]);
    setFileInfo({ file: null, isUploaded: false, description: null });
    !isNil(res) && setMedia([...fileList, res].flatMap(({ id }) => ({ id })));
  };

  const removeItem = idx => {
    const { id } = fileList.at(idx);
    const newFileList = [...fileList];
    newFileList.splice(idx, 1);
    setFileList(newFileList);
    handleRemoveMedia(id);
  };

  const handleCancel = () => {
    if (!isNil(fileInfo.file)) setFileInfo({ file: null, isUploaded: false });
    if (fileList.length > mediaFiles.length) {
      handleRemoveMedia(fileList[fileList.length - 1].id);
      const tempList = dropRight(fileList, 1);
      setFileList(tempList);
      updateData('isUploaded', false);
    }
  };

  const handleRemoveMedia = async id => {
    const [, err] = await removeMedia(id);
    err && setError(err);
  };

  return (
    <div className={`${formSectionWrapper(theme)} ${fileUploadBlock}`}>
      <h4 className={title}>{getText('uploadFile')}</h4>
      <p>{getText('optimalResultsForUpload')}</p>
      <p>
        <b>{getText('approvedFileExtensions')}</b>
      </p>
      <div className={filesContainer}>
        <Dragger {...fileUploadConfig}>
          <p className="upload-drag-icon">
            {fileInfo.isUploaded ? (
              <>
                <FeatherIcon icon="check-circle" size={60} strokeWidth={1} stroke={theme.primary_blue} />
                <p className="upload-text">{getText('uploadSuccessful')}</p>
              </>
            ) : (
              <>
                <FeatherIcon icon="upload-cloud" size={60} strokeWidth={1} stroke={theme.primary_blue} />
                <p className="upload-text">{getText('dragAndDropBrowseFiles')}</p>
              </>
            )}
          </p>
        </Dragger>
        <ul className={filesListStyles}>
          {fileList?.map((file, idx) => (
            <li key={idx} className={fileListItem(theme)}>
              <div>
                <p className={`file-name ${toggleHiddenText}`}>{file?.name}</p>
                <p className={`file-description ${toggleHiddenText}`}>{file?.description}</p>
              </div>
              <FeatherIcon icon="trash" size={15} strokeWidth={1} onClick={() => removeItem(idx)} />
            </li>
          ))}
        </ul>
      </div>
      <p>{getText('fileType')}</p>
      <div className="flex upload-field-type">
        <Input
          type="text"
          placeholder={getText('enterFileName')}
          onChange={e => updateData('description', e.target.value)}
          disabled={isNil(fileInfo?.file)}
          value={fileInfo?.description}
          className={mr({ lg: 10 })}
        />
        <Button type="default" small onClick={handleCancel} disabled={isNil(fileInfo?.file)}>
          {getText('cancel')}
        </Button>
        <Button
          className={ml({ lg: 10 })}
          type="primary"
          small
          onClick={handleUpload}
          disabled={isNil(fileInfo?.description)}
        >
          {getText('submit')}
        </Button>
      </div>
    </div>
  );
};

UploadFiles.propTypes = {
  setMedia: PropTypes.func,
  mediaFiles: PropTypes.array,
};

export default UploadFiles;
