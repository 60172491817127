import React, { useState, useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { useParams } from 'react-router-dom';
import { Row, Col, Tabs, Spin } from 'antd';
import { isNil, invert } from 'lodash-es';
import { SearchInput } from 'components/ui';
import ListingDeal from 'components/deal/ListingDeal';
import { tabsWrapper, searchWrapper, navigationWrapper } from 'screens/Private/MerchantOffice/components/dealsStyles';
import { Pagination, useUrlParams } from 'components/UIExternal';
import useError from 'services/errorHandling/useError';
import { getDealsPublicProfile } from 'services/api/dealsService';
import { mb, mt } from 'assets/css/globalCSS';
import { publicMerchantTab } from './styles';

const tabsConfig = {
  recent: 'recent',
  top: 'top',
};

const dealsSortingMap = {
  [tabsConfig.recent]: 4,
  [tabsConfig.top]: 0,
};

const filterKeys = {
  search: 'searchText',
  sorting: 'dealsSorting',
};

const DealsTab = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { queryParams } = useUrlParams();
  const { TabPane } = Tabs;
  const { setError } = useError();
  const { businessId } = useParams();
  const [activeTab, setActiveTab] = useState(
    invert(dealsSortingMap)[queryParams[filterKeys.sorting]] ?? tabsConfig.recent,
  );
  const [deals, setDeals] = useState(null);
  const paginationRef = useRef();

  const fetchDeals = async options => {
    const params = { ...options, [filterKeys.sorting]: dealsSortingMap[activeTab] };
    const [res, err] = await getDealsPublicProfile(businessId, params);
    err ? setError(err) : setDeals(res);
    return res;
  };

  const onTabClickHandler = newActiveTab => {
    if (newActiveTab === activeTab) return;
    setActiveTab(newActiveTab);
    paginationRef.current?.applyFilters({ [filterKeys.sorting]: dealsSortingMap[newActiveTab] });
  };

  return (
    <div className={publicMerchantTab(theme)}>
      <Row className={`${mb({ lg: 34, span: 30 })} ${navigationWrapper(theme)}`}>
        <Col lg={6} span={24} className={tabsWrapper(theme)}>
          <Tabs
            destroyInactiveTabPane
            defaultActiveKey={activeTab}
            activeKey={activeTab}
            onTabClick={onTabClickHandler}
          >
            <TabPane tab={getText('recentDeals')} key={tabsConfig.recent} />
            <TabPane tab={getText('topSellers')} key={tabsConfig.top} />
          </Tabs>
        </Col>
        <Col lg={{ span: 10, offset: 8 }} span={24} className={searchWrapper}>
          <SearchInput
            defaultValue={queryParams[filterKeys.search]}
            onPressEnter={val => paginationRef.current?.applyFilters({ [filterKeys.search]: val })}
            placeholder={getText('searchForDeals')}
          />
        </Col>
      </Row>
      <Pagination ref={paginationRef} onChange={fetchDeals} pageSize={5} filterKeys={filterKeys} />
      <Row className={`${mb({ lg: 34, span: 30 })} ${mt({ lg: 34 })}`}>
        {isNil(deals) ? (
          <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
            <Spin size="large" />
          </div>
        ) : (
          <Col lg={20} span={24}>
            {deals.items?.map(deal => (
              <ListingDeal key={deal.id} deal={deal} mainDealName={deal.name} isMerchant={deal?.isOwner} />
            ))}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DealsTab;
