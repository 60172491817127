import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useParams } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import { useTranslations } from '@veraio/strank';
import PublicAddressCard from 'screens/Public/PublicMerchant/components/PublicAddressCard';
import { getBusinessProfile } from 'services/api/businessesService';
import useError from 'services/errorHandling/useError';
import { mb } from 'assets/css/globalCSS';
import { publicMerchantTab, aboutTitle, descriptionParagraph, addressesTitle } from './styles';

const ProfileTab = () => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const { businessId } = useParams();
  const [profileInfo, setProfileInfo] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [res, err] = await getBusinessProfile(businessId);
    err && setError(err);
    setProfileInfo(res);
  };

  return (
    <div className={publicMerchantTab(theme)}>
      {profileInfo ? (
        <>
          <h5 className={aboutTitle(theme)}>
            {getText('about')} {getDynamicTranslation(profileInfo?.businessDetails)?.name}
          </h5>
          <p className={descriptionParagraph(theme)}>{profileInfo?.description}</p>
          <h6 className={addressesTitle(theme)}>
            {getText('assignedAddresses')}: {profileInfo?.addresses?.length}
          </h6>
          <Row className={mb({ lg: 40, span: 30 })} gutter={[24, 24]}>
            {profileInfo?.addresses?.map(address => (
              <Col className="gutter-row" lg={8} span={24}>
                <PublicAddressCard address={address} />
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default ProfileTab;
